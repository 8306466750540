<template>
  <div>
    <el-dialog
      class="home-member-guide-dialog"
      title="编辑首页会员开卡"
      :visible.sync="showDialog"
      width="60%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <div class="home-member-guide">
        <div class="df-row">
          <div class="df-col">
            <el-button type="primary" size="mini" @click="onSearchPath"
              >查看功能页面路径</el-button
            >
          </div>
        </div>

        <!-- <el-form
          :model="submitData"
          ref="ruleForm"
          label-width="100px"
          class="setting-box"
        >
          <el-form-item label="会员开卡图片">
            <div class="setting-item">
              <el-input class="link" v-model="submitData.LinkPath" size="mini">
                <template slot="prepend">链接</template>
              </el-input>

              <el-select
                v-model="submitData.CardGuid"
                placeholder="选择指定会员卡"
                size="mini"
              >
                <el-option
                  v-for="item in cardList"
                  :key="item.CouponGuid"
                  :label="item.CardName"
                  :value="item.CardGuid"
                >
                </el-option>
              </el-select>

              <el-input
                class="icon"
                v-model="submitData.PictureUrl"
                size="mini"
                :disabled="true"
              >
                <template slot="prepend">图片</template>
              </el-input>

              <el-button size="mini" @click="onShowGellery">选择图片</el-button>
            </div>
          </el-form-item>
        </el-form> -->
        <div class="setting-box">
          <div class="setting-item">
            <span class="title">会员开卡图片</span>

            <div class="row">
              <el-input class="text" v-model="linkPath" size="mini">
                <template slot="prepend">链接</template>
              </el-input>
            </div>

            <div class="row">
              <i
                v-if="submitData.PictureUrl"
                class="el-icon-error"
                @click="onClearMedia"
              ></i>
              <el-image
                :src="submitData.PictureUrl || imgUrl"
                fit="contain"
              ></el-image>
            </div>

            <el-button size="mini" @click="onShowGellery">选择图片</el-button>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button
          type="primary"
          :disabled="submitDisabled"
          @click="onSubmitData"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <DFGalleryPictrue
      ref="DFGalleryPictrue"
      @onComfirm="onSaveMedia"
    ></DFGalleryPictrue>

    <DfPathTipsDialog ref="PathTipsDailog"></DfPathTipsDialog>
  </div>
</template>

<script>
import Wxmpdiy from "@/api/wxmpdiy.js";

import img from "@/assets/img/no-pictrue-rectangle.png";

export default {

  data() {
    return {
      code: "HomeCardGuide",
      tempKeyName: "",
      linkPath: "",
      submitData: {
        LinkPath: "",
        PictureUrl: "",
        CardGuid: "",
      },
      imgUrl: img,
      cardList: [],
      showDialog: false,
      submitDisabled: false,
    };
  },

  methods: {
    // 获取初始化数据
    async initFeatureData() {
      try {
        let { data } = await Wxmpdiy.initFeatureData({
          code: this.code,
        });
        this.submitData = data;
        this.linkPath = (data.LinkPath || '') + (data.CardGuid || '');
      } catch (error) {
        console.log(error);
      }
    },

    // 获取可售卖会员卡数据
    async getBuyCardSelector() {
      try {
        let { data } = await Wxmpdiy.getBuyCardSelector();
        this.cardList = data;
      } catch (error) {
        console.log(error);
      }
    },

    // 提交优惠券主题页数据
    async onSubmitData() {
      let { submitData, code, linkPath } = this;

      let findIndex = linkPath.indexOf("=");
      submitData.LinkPath = linkPath.slice(0, findIndex + 1);
      submitData.CardGuid = linkPath.slice(findIndex + 1);
      // console.log(url, cardGuid);
      if (!submitData.PictureUrl) {
        return this.$message.error("请选择图片");
      } else if (submitData.LinkPath && !submitData.CardGuid) {
        return this.$message.error("请选择指定会员卡");
      }

      this.submitDisabled = true;
      try {
        let { errcode } = await Wxmpdiy.saveFeature({
          Code: code,
          Data: submitData,
        });
        if (errcode == 0) {
          this.$message.success("编辑成功~");
          this.onCloseDialog();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.submitDisabled = false;
      }
    },

    // 查看功能页面路径
    onSearchPath() {
      this.$refs.PathTipsDailog.onShowDialog();
    },

    // 打开图库
    onShowGellery() {
      this.$refs.DFGalleryPictrue.onShowDialog();
    },

    // 保存选中图片
    onSaveMedia(event) {
      this.submitData.PictureUrl = event[0].MediaUrl;
    },

    // 清除图片
    onClearMedia() {
      this.submitData.PictureUrl = "";
    },

    // 打开对话框
    onShowDialog() {
      this.initFeatureData();
      this.getBuyCardSelector();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.home-member-guide-dialog {
  ::v-deep .el-dialog {
    min-width: 1000px;
  }
}

.home-member-guide {
  .df-row {
    .el-select {
      width: 320px;
    }
  }

  .setting-box {
    ::v-deep .setting-item {
      .flex-row();
      align-items: center;

      > * {
        margin-right: 20px;
      }

      .row {
        position: relative;
        .flex-col();
        align-items: unset;
        .el-input {
          margin: 10px 0;
        }

        i {
          position: absolute;
          top: -5px;
          right: -5px;
          font-size: 20px;
          cursor: pointer;
          z-index: 10;
        }
      }

      .el-image {
        height: 100px;
        box-sizing: border-box;
      }

      .el-input,
      .el-select {
        margin-right: 20px;
      }

      .el-input {
        width: 500px;
      }

      .icon {
        width: 200px;
      }
    }
  }
}
</style>
