<template>
  <div>
    <el-dialog
      class="home-member-guide-dialog"
      title="编辑首页标题导航组"
      :visible.sync="showDialog"
      width="40%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <div class="home-member-guide">
        <div class="df-row">
          <div class="df-col">
            <el-button type="primary" size="mini" @click="onSearchPath"
              >查看功能页面路径</el-button
            >
          </div>
          <div class="df-col">
            <el-button type="primary" size="mini" @click="onInit"
              >初始化</el-button
            >
          </div>
        </div>

        <div class="setting-box">
          <TemplateSetting :selectedTempInfo="submitData"></TemplateSetting>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button
          type="primary"
          :disabled="submitDisabled"
          @click="onSubmitData"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <DFGalleryPictrue
      ref="DFGalleryPictrue"
      @onComfirm="onSaveMedia"
    ></DFGalleryPictrue>

    <DfPathTipsDialog ref="PathTipsDailog"></DfPathTipsDialog>
  </div>
</template>

<script>
import TemplateSetting from "@/views/setting/components/template-setting.vue";

import Wxmpdiy from "@/api/wxmpdiy.js";

export default {
  components: { TemplateSetting },

  data() {
    return {
      code: "HomeTitleNavigation",
      tempKeyName: "",
      submitData: {
        BackColor: null,
        Code: "TitleNaviGroup",
        Name: "标题导航组",
        NaviItems: [],
        PerLineCount: 0,
      },
      typeList: [],
      batchList: [],
      showDialog: false,
      submitDisabled: false,
    };
  },

  methods: {
    // 获取初始化数据
    async initFeatureData() {
      try {
        let { data } = await Wxmpdiy.initFeatureData({
          code: this.code,
        });
        this.submitData = data;
      } catch (error) {
        console.log(error);
      }
    },

    // 提交优惠券主题页数据
    async onSubmitData() {
      let { submitData, code } = this;

      this.submitDisabled = true;
      try {
        let { errcode } = await Wxmpdiy.saveFeature({
          Code: code,
          Data: {
            ...submitData,
            PerLineCount: Number(submitData.PerLineCount),
          },
        });
        if (errcode == 0) {
          this.$message.success("编辑成功~");
          this.onCloseDialog();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.submitDisabled = false;
      }
    },

    // 初始化按钮点击事件
    onInit() {
      this.submitData = {
        BackColor: "#fff",
        Code: "TitleNaviGroup",
        Name: "标题导航组",
        NaviItems: [
          { Title: "一键WIFI", Subtitle: "自动连接", LinkPath: "WiFi" },
          {
            Title: "积分兑换",
            Subtitle: "积分商城",
            LinkPath: "pages/inte/inte-shopping",
          },
          {
            Title: "我的优惠券",
            Subtitle: "优惠多多",
            LinkPath: "pages/coupon/mine-list",
          },
        ],
        PerLineCount: 3,
      };
    },

    // 查看功能页面路径
    onSearchPath() {
      this.$refs.PathTipsDailog.onShowDialog();
    },

    // 打开图库
    onShowGellery(keyName) {
      this.tempKeyName = keyName;
      this.$refs.DFGalleryPictrue.onShowDialog();
    },

    // 保存选中图片
    onSaveMedia(event) {
      this.submitData[this.tempKeyName].IconUrl = event[0].MediaUrl;
    },

    // 打开对话框
    onShowDialog() {
      this.initFeatureData();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.home-member-guide-dialog {
  ::v-deep .el-dialog {
    min-width: 500px;
  }
}

.home-member-guide {
  .df-row {
    .el-select {
      width: 320px;
    }
  }

  .setting-box {
    height: 500px;

    .template-setting {
      width: 100%;
    }

    ::v-deep .setting-item {
      .flex-row();
      align-items: center;

      .el-input-group__prepend {
        padding: 0 5px;
      }

      .el-input {
        margin-right: 20px;
      }

      .text {
        width: 140px;
      }

      .link {
        width: 500px;
      }

      .icon {
        width: 200px;
      }
    }
  }
}
</style>
