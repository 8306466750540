<template>
  <div>
    <el-dialog
      class="sync-dialog"
      title="同步功能设置"
      :visible.sync="showDialog"
      width="40%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <div class="sync-body">
        <div class="df-row">
          <div class="df-col">
            <!-- 功能选择器 -->
            <el-select v-model="submitData.FromCode" placeholder="请选择功能">
              <el-option
                v-for="item in featureList"
                :key="item.Code"
                :label="item.Name"
                :value="item.Code"
              >
              </el-option>
            </el-select>
          </div>

          <div class="df-col">
            <!-- 门店选择器 -->
            <el-select
              v-model="submitData.ToStoresGuids"
              multiple
              collapse-tags
              clearable
              placeholder="请选择门店"
              @change="onChangeStores"
            >
              <el-option
                v-for="item in storesList"
                :key="item.StoresGuid"
                :label="item.StoresName"
                :value="item.StoresGuid"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button
          type="primary"
          :disabled="submitDisabled"
          @click="onSubmitData"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Wxmpdiy from "@/api/wxmpdiy.js";

export default {
  data() {
    return {
      submitData: {
        // 提交数据
        FromCode: "",
        ToStoresGuids: [],
      },
      featureList: [], // 功能设置列表
      storesList: [], // 门店列表
      showDialog: false, // 对话框显示
      submitDisabled: false, // 提交防抖
    };
  },

  methods: {
    // 确定按钮点击事件
    onSubmitData() {
      let { submitData } = this;
      let featureName = this.featureList.filter(
        (item) => item.Code == submitData.FromCode
      )[0].Name;

      if (!submitData.ToStoresGuids.length) {
        return this.$message.error("请选择门店");
      }
      this.submitDisabled = true;

      this.$confirm(
        `同步后将无法恢复原数据,确定要将【${featureName}】功能同步至所选门店吗？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            let { errcode } = await Wxmpdiy.copyFeatureTo(submitData);
            if (errcode == 0) {
              this.$message.success("同步成功");
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.submitDisabled = false;
          }
        })
        .catch(() => {
          this.submitDisabled = false;
        });
    },

    // 改变门店选中事件
    onChangeStores(event) {
      let res = event.filter((item) => item == "");
      if (res.length > 0) {
        this.submitData.ToStoresGuids = [];
        this.storesList.forEach((e) => {
          this.submitData.ToStoresGuids.push(e.StoresGuid);
        });
        this.submitData.ToStoresGuids.shift();
      }
    },

    // 打开对话框
    onShowDialog({ storesList = [], featureList = [], selected = {} }) {
      let selectStoreGuid = window.sessionStorage.getItem("selectStoreGuid")
      let storesArr = JSON.parse(JSON.stringify(storesList)).filter(
        (item) => item.StoresGuid != selectStoreGuid
      );
      let featureArr = JSON.parse(JSON.stringify(featureList)).filter(item=>{
        if(item.Code != 'CouponActivity' && item.State){
            return item
        }
      });
      if (storesArr.length > 0) {
        storesArr = [{ StoresGuid: "", StoresName: "全部" }, ...storesArr];
      }

      this.storesList = storesArr;
      this.featureList = featureArr;
      this.submitData.FromCode = selected.Code;
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.df-row {
  .df-col {
    .el-select {
      width: 280px;
    }
  }
}
</style>
