<template>
  <div>
    <el-dialog
      class="home-member-guide-dialog"
      title="编辑首页会员信息"
      :visible.sync="showDialog"
      width="60%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <div class="home-member-guide">
        <div class="df-row">
          <div class="df-col">
            <el-button type="primary" size="mini" @click="onSearchPath"
              >查看功能页面路径</el-button
            >
          </div>
          <div class="df-col">
            <el-button type="primary" size="mini" @click="onInit"
              >初始化</el-button
            >
          </div>
        </div>

        <div class="setting-box">
          <div class="setting-item">
            <span class="title">第一个链接</span>

            <div class="row">
              <el-input
                class="text"
                v-model="submitData.GuideLeft.Text"
                size="mini"
              >
                <template slot="prepend">文字</template>
              </el-input>

              <el-input
                class="link"
                v-model="submitData.GuideLeft.LinkPath"
                size="mini"
              >
                <template slot="prepend">链接</template>
              </el-input>
            </div>

            <div class="row">
              <i v-if="submitData.GuideLeft.IconUrl" class="el-icon-error" @click="onClearMedia('GuideLeft')"></i>
              <el-image
                :src="submitData.GuideLeft.IconUrl || imgUrl"
                fit="contain"
              ></el-image>
            </div>

            <el-button size="mini" @click="onShowGellery('GuideLeft')"
              >选择图片</el-button
            >
          </div>

          <div class="setting-item">
            <span class="title">第二个链接</span>

            <div class="row">
              <el-input
                class="text"
                v-model="submitData.GuideMiddle.Text"
                size="mini"
              >
                <template slot="prepend">文字</template>
              </el-input>

              <el-input
                class="link"
                v-model="submitData.GuideMiddle.LinkPath"
                size="mini"
              >
                <template slot="prepend">链接</template>
              </el-input>
            </div>

            <div class="row">
              <i v-if="submitData.GuideMiddle.IconUrl" class="el-icon-error" @click="onClearMedia('GuideMiddle')"></i>
              <el-image
                :src="submitData.GuideMiddle.IconUrl || imgUrl"
                fit="contain"
              ></el-image>
            </div>

            <el-button size="mini" @click="onShowGellery('GuideMiddle')"
              >选择图片</el-button
            >
          </div>

          <div class="setting-item">
            <span class="title">第三个链接</span>

            <div class="row">
              <el-input
                class="text"
                v-model="submitData.GuideRight.Text"
                size="mini"
              >
                <template slot="prepend">文字</template>
              </el-input>

              <el-input
                class="link"
                v-model="submitData.GuideRight.LinkPath"
                size="mini"
              >
                <template slot="prepend">链接</template>
              </el-input>
            </div>

            <div class="row">
              <i v-if="submitData.GuideRight.IconUrl" class="el-icon-error" @click="onClearMedia('GuideRight')"></i>
              <el-image
                :src="submitData.GuideRight.IconUrl || imgUrl"
                fit="contain"
              ></el-image>
            </div>

            <el-button size="mini" @click="onShowGellery('GuideRight')"
              >选择图片</el-button
            >
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button
          type="primary"
          :disabled="submitDisabled"
          @click="onSubmitData"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <DFGalleryPictrue
      ref="DFGalleryPictrue"
      @onComfirm="onSaveMedia"
    ></DFGalleryPictrue>

    <DfPathTipsDialog ref="PathTipsDailog"></DfPathTipsDialog>
  </div>
</template>

<script>
import Wxmpdiy from "@/api/wxmpdiy.js";

import img from "@/assets/img/no-pictrue-rectangle.png"
export default {

  data() {
    return {
      code: "HomeMemberGuide",
      tempKeyName: "",
      submitData: {
        GuideLeft: { IconUrl: "", Text: "" },
        GuideMiddle: { IconUrl: "", Text: "" },
        GuideRight: { IconUrl: "", Text: "" },
      },
      imgUrl: img,
      typeList: [],
      batchList: [],
      showDialog: false,
      submitDisabled: false,
    };
  },

  methods: {
    // 获取初始化数据
    async initFeatureData() {
      try {
        let { data } = await Wxmpdiy.initFeatureData({
          code: this.code,
        });
        this.submitData = data;
      } catch (error) {
        console.log(error);
      }
    },

    // 提交优惠券主题页数据
    async onSubmitData() {
      let { submitData, code } = this;
      let { GuideLeft, GuideMiddle, GuideRight } = submitData;

      if (!GuideLeft.Text || !GuideMiddle.Text || !GuideRight.Text) {
        return this.$message.error("三个链接的文字都不能为空");
      } else if (
        !GuideLeft.LinkPath ||
        !GuideMiddle.LinkPath ||
        !GuideRight.LinkPath
      ) {
        return this.$message.error("三个链接的链接都不能为空");
      }

      this.submitDisabled = true;
      try {
        let { errcode } = await Wxmpdiy.saveFeature({
          Code: code,
          Data: submitData,
        });
        if (errcode == 0) {
          this.$message.success("编辑成功~");
          this.onCloseDialog();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.submitDisabled = false;
      }
    },

    // 初始化按钮点击事件
    onInit(){
      let baseUrl = 'http://www.difengsoft.com/statics/store-web'
      this.submitData = {
        GuideLeft: { IconUrl: baseUrl + "/user-card.png", Text: " 我的会员卡", LinkPath: 'pages/card/use-card-item' },
        GuideMiddle: { IconUrl: baseUrl + "/reservation.png", Text: " 我的预约", LinkPath: 'pages/reservation/mine-list' },
        GuideRight: { IconUrl: baseUrl + "/history.png", Text: " 历史消费", LinkPath: 'pages/transaction/transaction' },
      }
    },

    // 查看功能页面路径
    onSearchPath() {
      this.$refs.PathTipsDailog.onShowDialog();
    },

    // 打开图库
    onShowGellery(keyName) {
      this.tempKeyName = keyName;
      this.$refs.DFGalleryPictrue.onShowDialog();
    },

    // 保存选中图片
    onSaveMedia(event) {
      this.submitData[this.tempKeyName].IconUrl = event[0].MediaUrl;
    },

    // 清除图片
    onClearMedia(text){
      this.submitData[text].IconUrl = '';
    },

    // 打开对话框
    onShowDialog() {
      this.initFeatureData();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.home-member-guide-dialog {
  ::v-deep .el-dialog {
    min-width: 1000px;
  }
}

.home-member-guide {
  .df-row {
    .el-select {
      width: 320px;
    }
  }

  .setting-box {
    ::v-deep .setting-item {
      .flex-row();
      align-items: center;
      padding: 10px 0;

      > * {
        margin-right: 20px;
      }

      .row {
        position: relative;
        .flex-col();
        align-items: unset;
        .el-input {
          margin: 10px 0;
        }

        i{
          position: absolute;
          top: -5px;
          right: -5px;
          font-size: 20px;
          cursor: pointer;
          z-index: 10;
        }
      }

      .el-image {
        height: 100px;
        box-sizing: border-box;
      }

      .el-input-group__prepend {
        // padding: 0 5px;
      }

      .el-input {
        margin-right: 20px;
        width: 600px;
      }

      .text {
        // width: 140px;
      }

      .link {
        // width: 500px;
      }

      .icon {
        width: 200px;
      }
    }
  }
}
</style>
