<template>
  <div class="feature">
    <!-- 选择门店 -->
    <div v-if="authType == 'H'" class="df-row">
      <div class="df-col">
        <el-select
          v-model="submitData.stores_guid"
          size="mini"
          clearable
          placeholder="请选择门店"
        >
          <el-option
            v-for="item in storesList"
            :key="item.StoresGuid"
            :label="item.StoresName"
            :value="item.StoresGuid"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="getFeatureList"
          >刷 新</el-button
        >
      </div>
    </div>

    <!-- 功能设置列表 -->
    <el-table
      :data="featureList"
      border
      stripe
      :max-height="tableMaxHeight"
      style="width: 100%;"
      size="mini"
    >
      <el-table-column prop="Name" label="功能名称"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scoped">
          <div class="control">
            <el-checkbox
              v-if="!scoped.row.State"
              :value="scoped.row.State"
              :checked="scoped.row.State ? true : false"
              :true-label="1"
              :false-label="0"
              size="mini"
              border
              @change="onChangeFeature(scoped.row)"
              >启用</el-checkbox
            >

            <el-button
              v-if="scoped.row.Code != 'CouponActivity' && authType == 'H'"
              type="success"
              size="mini"
              :disabled="!scoped.row.State"
              @click="onShowSync(scoped.row)"
              >同步</el-button
            >

            <el-button
              type="primary"
              size="mini"
              :disabled="!scoped.row.State"
              @click="onShowEdit(scoped.row)"
              >编辑</el-button
            >

            <el-button
              :type="scoped.row.Hidden ? 'warning' : 'danger'"
              size="mini"
              :disabled="!scoped.row.State"
              @click="onHiddenFeature(scoped.row)"
              >{{ scoped.row.Hidden ? "显示" : "隐藏" }}</el-button
            >
            <!-- <el-button type="danger" size="mini" @click="">删除</el-button> -->
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 首页图标导航组对话框 -->
    <HomeIconNavigationDialog
      ref="HomeIconNavigationDialog"
    ></HomeIconNavigationDialog>

    <!-- 首页标题导航组对话框 -->
    <HomeTitleNavigationDialog
      ref="HomeTitleNavigationDialog"
    ></HomeTitleNavigationDialog>

    <!-- 首页会员信息对话框 -->
    <HomeMemberGuideDialog ref="HomeMemberGuideDialog"></HomeMemberGuideDialog>

    <!-- 顾客领券对话框 -->
    <CouponActivityDialog ref="CouponActivityDialog"></CouponActivityDialog>

    <!-- 首页会员开卡对话框 -->
    <HomeCardGuideDialog ref="HomeCardGuideDialog"></HomeCardGuideDialog>

    <!-- 同步设置对话框 -->
    <syncDialog ref="syncDialog"></syncDialog>
  </div>
</template>

<script>
import CouponActivityDialog from "./components/coupon-activity-dialog.vue";
import HomeMemberGuideDialog from "./components/home-member-guide-dialog.vue";
import HomeIconNavigationDialog from "./components/home-icon-navigation-dialog.vue";
import HomeCardGuideDialog from "./components/home-card-guide-dialog.vue";
import HomeTitleNavigationDialog from "./components/home-title-navigation-dialog.vue";
import syncDialog from "./components/sync-dialog.vue";

import Wxmpdiy from "@/api/wxmpdiy.js";

export default {
  components: {
    CouponActivityDialog,
    HomeMemberGuideDialog,
    HomeIconNavigationDialog,
    HomeCardGuideDialog,
    HomeTitleNavigationDialog,
    syncDialog,
  },

  data() {
    return {
      authType: "", // 管理权限
      tableMaxHeight: "", // 表格最大高度
      submitData: {
        stores_guid: "",
      },
      featureList: [], // 功能列表
      storesList: [], // 门店列表
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);

    let accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    this.storesList = accountInfo.manageStoresList;
    this.authType = accountInfo.authType;
    if (this.authType == "H") {
      console.log(this.storesList);
      this.submitData.stores_guid = this.storesList[0].StoresGuid;
    }

    this.getFeatureList();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 20 + 40 + 48 + 56);
    },

    // 获取门店列表
    async getFeatureList() {
      try {
        let { data } = await Wxmpdiy.getFeatureList(this.submitData);
        this.featureList = data;
      } catch (error) {
        console.log(error);
      }
    },

    // 改变功能设置启用/禁用
    onChangeFeature(item) {
      let text = !item.State ? "启用" : "禁用";
      this.$confirm(
        `${text == "禁用" ? `${text}将会删除设置，` : ""}确定要${text}【${
          item.Name
        }】功能吗？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(async (result) => {
        try {
          let { errcode } = await Wxmpdiy.enableFeature({
            code: item.Code,
            state: item.State ? 0 : 1,
          });
          if (errcode == 0) {
            item.State = !item.State;
          }
        } catch (error) {
          console.log(error);
        }
      });
    },

    // 隐藏/显示功能设置
    onHiddenFeature(item) {
      let text = item.Hidden ? "显示" : "隐藏";
      this.$confirm(`确定要${text}【${item.Name}】功能吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async (result) => {
        try {
          let { errcode } = await Wxmpdiy.hideFeature({
            code: item.Code,
            hidden: item.Hidden ? 0 : 1,
          });
          if (errcode == 0) {
            this.$message.success(`${text}成功`);
            item.Hidden = !item.Hidden;
          }
        } catch (error) {
          console.log(error);
        }
      });
    },

    // 同步功能设置
    onShowSync(item) {
      let { storesList, featureList } = this;
      this.$refs.syncDialog.onShowDialog({
        storesList,
        featureList,
        selected: item,
      });
    },

    // 查看功能页面路径
    // onSearchPath() {
    //   this.$refs.PathTipsDailog.onShowDialog();
    // },

    // 打开编辑对话框
    onShowEdit(event) {
      let { Code } = event;
      this.$refs[`${Code}Dialog`].onShowDialog();
    },
  },
};
</script>

<style lang="less" scoped>
.feature {
  .control .el-checkbox {
    margin-right: 20px;
  }
}
</style>
