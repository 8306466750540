<template>
  <div>
    <el-dialog
      title="编辑顾客领券"
      :visible.sync="showDialog"
      width="45%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <div class="coupon-activity">
        <div class="df-row">
          <div class="df-col">
            <el-select
              v-model="couponData.CouponGuid"
              placeholder="请选择优惠券类型"
              size="mini"
              @change="getCouponBatchList"
            >
              <el-option
                v-for="item in typeList"
                :key="item.CouponGuid"
                :label="
                  `${item.CouponNo} - ${item.CouponTypeName} - ${item.CouponName}`
                "
                :value="item.CouponGuid"
              >
              </el-option>
            </el-select>
          </div>

          <div class="df-col">
            <el-select
              v-model="couponData.ExpeCouponGuid"
              placeholder="请选择优惠券批次"
              size="mini"
            >
              <el-option
                v-for="item in batchList"
                :key="item.ExpeCouponGuid"
                :label="
                  `${util.dateFormat(
                    'YYYY-mm-dd HH:MM',
                    new Date(item.AddTime * 1000)
                  )} - 已发券 ${item.GiveOut} - 已领取 ${item.Receive}`
                "
                :value="item.ExpeCouponGuid"
              >
              </el-option>
            </el-select>
          </div>

          <div class="df-col">
            <el-button type="primary" size="mini" @click="onAddCouponItem"
              >添 加</el-button
            >
          </div>
        </div>

        <div class="df-row">
          <div class="coupon-box">
            <div
              class="coupon-item"
              v-for="(item, index) in tableList"
              :key="index"
            >
              {{
                `${index + 1}、${item.CouponTypeName} - ${item.CouponNo} - ${
                  item.CouponName
                }（${util.dateFormat(
                  "YYYY-mm-dd HH:MM",
                  new Date(item.AddTime * 1000)
                )} - 已发券 ${item.GiveOut} - 已领取 ${item.Receive}）`
              }}
              <el-button
                type="danger"
                size="mini"
                :disabled="addDisabled"
                @click="onDelCouponItem(index)"
                >删 除</el-button
              >
            </div>
          </div>
        </div>

        <el-form
          :model="submitData"
          :rules="submitRules"
          ref="ruleForm"
          label-width="100px"
          class="setting-box"
        >
          <el-form-item label="介绍图片" prop="ActivityPicUrl">
            <div class="setting-item">
              <div class="row">
                <i
                  v-if="submitData.ActivityPicUrl"
                  class="el-icon-error"
                  @click="submitData.ActivityPicUrl = ''"
                ></i>
                <el-image
                  :style="submitData.ActivityPicUrl ? 'height: 300px' : ''"
                  :src="submitData.ActivityPicUrl || imgUrl"
                  fit="contain"
                ></el-image>
              </div>

              <el-button size="mini" @click="onShowGellery('ActivityPicUrl')"
                >选择图片</el-button
              >
            </div>
          </el-form-item>

          <el-form-item label="按钮图片" prop="ButtonPicUrl">
            <div class="setting-item">
              <div class="row">
                <i
                  v-if="submitData.ButtonPicUrl"
                  class="el-icon-error"
                  @click="submitData.ButtonPicUrl = ''"
                ></i>
                <el-image
                  :src="submitData.ButtonPicUrl || imgUrl"
                  fit="contain"
                ></el-image>
              </div>

              <el-button size="mini" @click="onShowGellery('ButtonPicUrl')"
                >选择图片</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button
          type="primary"
          :disabled="submitDisabled"
          @click="onSubmitData"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <DFGalleryPictrue
      ref="DFGalleryPictrue"
      @onComfirm="onSaveMedia"
    ></DFGalleryPictrue>
  </div>
</template>

<script>
import Wxmpdiy from "@/api/wxmpdiy.js";

import img from "@/assets/img/no-pictrue-rectangle.png";
export default {
  data() {
    return {
      code: "CouponActivity",
      imgUrl: img,
      tempKeyName: "",
      submitData: {
        couponItems: [],
        ActivityPicUrl: "",
        ButtonPicUrl: "",
      },
      couponData: {
        CouponGuid: "",
        ExpeCouponGuid: "",
      },
      submitRules: {
        ActivityPicUrl: [
          { required: true, message: "请输入介绍图片", trigger: "blur" },
        ],

        ButtonPicUrl: [
          { required: true, message: "请输入按钮图片", trigger: "blur" },
        ],
      },
      typeList: [],
      batchList: [],
      tableList: [],
      showDialog: false,
      submitDisabled: false,
      addDisabled: false,
    };
  },

  methods: {
    // 获取初始化数据
    async initFeatureData() {
      try {
        let { data } = await Wxmpdiy.initFeatureData({
          code: this.code,
        });
        this.submitData = data;
        await this.getCouponTypeList();
        // if (await data.ExpeCouponGuid) {
        //   await this.getCouponBatchList();
        // }
        if (data.CouponItems.length > 0) {
          let arr = [];

          await data.CouponItems.forEach((item) => {
            arr.push(item.ExpeCouponGuid);
          });

          await this.$nextTick(() => {
            this.getCouponActivityDataViewer({ ExpeCouponGuids: arr });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    // 初始化优惠券批次数据
    async getCouponActivityDataViewer(event) {
      this.addDisabled = true;
      try {
        let { data } = await Wxmpdiy.getCouponActivityDataViewer(event);
        await this.$nextTick(() => {
          this.tableList = [...this.tableList, ...data];
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.addDisabled = false;
      }
    },

    // 获取优惠券类型列表
    async getCouponTypeList() {
      try {
        let { data } = await Wxmpdiy.getCouponTypeList();
        this.typeList = data;
      } catch (error) {
        console.log(error);
      }
    },

    // 获取优惠券批次列表
    async getCouponBatchList() {
      try {
        let { data } = await Wxmpdiy.getCouponBatchList({
          coupon_guid: this.couponData.CouponGuid,
        });
        this.batchList = data;
        this.couponData.ExpeCouponGuid = ""
      } catch (error) {
        console.log(error);
      }
    },

    // 提交优惠券主题页数据
    async onSubmitData() {
      let that = this;
      let { submitData, code, tableList } = this;
      let couponItems = [];
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          if (!submitData.ActivityPicUrl) {
            return this.$message.error("请上传介绍图片");
          } else if (!submitData.ButtonPicUrl) {
            return this.$message.error("请上传按钮图片");
          }
          this.submitDisabled = true;

          await this.tableList.forEach((item) => {
            couponItems.push({
              CouponGuid: item.CouponGuid,
              ExpeCouponGuid: item.ExpeCouponGuid,
            });
          });

          submitData.CouponItems = couponItems;

          try {
            let { errcode } = await Wxmpdiy.saveFeature({
              Code: code,
              Data: submitData,
            });
            if (errcode == 0) {
              this.$message.success("编辑成功~");
              this.onCloseDialog();
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.submitDisabled = false;
          }
        }
      });
    },

    // 新增一行优惠券
    onAddCouponItem() {
      if (!this.couponData.CouponGuid) {
        return this.$message.error("请选择优惠券类型");
      } else if (!this.couponData.ExpeCouponGuid) {
        return this.$message.error("请选择优惠券批次");
      } else if(this.tableList.findIndex(item=>item.CouponGuid === this.couponData.CouponGuid) != -1){
        return this.$message.error("不能重复添加该类型的优惠券")
      }

      this.getCouponActivityDataViewer({
        ExpeCouponGuids: [this.couponData.ExpeCouponGuid],
      });
    },

    // 删除一行优惠券
    onDelCouponItem(index) {
      this.tableList.splice(index, 1);
      // console.log(this.submitData);
    },

    // 打开图库
    onShowGellery(keyName) {
      this.tempKeyName = keyName;
      this.$refs.DFGalleryPictrue.onShowDialog();
    },

    // 保存选中图片
    onSaveMedia(event) {
      this.submitData[this.tempKeyName] = event[0].MediaUrl;
    },

    // 重置页面数据
    resetPageData(){
      this.submitData = {
        couponItems: [],
        ActivityPicUrl: "",
        ButtonPicUrl: "",
      };
      this.couponData = {
        CouponGuid: "",
        ExpeCouponGuid: "",
      };
      this.tableList = []
    },

    // 打开对话框
    onShowDialog() {
      this.initFeatureData();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.$refs["ruleForm"].resetFields();
      this.resetPageData()
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-dialog {
  min-width: 700px;
}

.coupon-activity {
  max-height: 400px;
  overflow-y: auto;

  .df-row {
    .el-select {
      width: 320px;
    }

    .coupon-box {
      .flex-col;

      > div {
        width: 100%;
        margin: 10px 0;
      }
    }
  }

  .setting-box {
    ::v-deep .setting-item {
      .flex-row();

      > * {
        margin-right: 20px;
      }

      .row {
        position: relative;
        .flex-col();
        align-items: unset;

        i {
          position: absolute;
          top: -5px;
          right: -5px;
          font-size: 20px;
          cursor: pointer;
          z-index: 10;
        }
      }

      .el-image {
        height: 100px;
        box-sizing: border-box;
      }

      .el-input {
        width: 500px;
        margin-right: 20px;
      }
    }
  }
}
</style>
